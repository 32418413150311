module.exports = [{
      plugin: require('/home/runner/work/main-page/main-page/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/main-page/main-page/src/intl","languages":["en","tr","zh","ja"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('/home/runner/work/main-page/main-page/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/runner/work/main-page/main-page/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/main-page/main-page/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144501573-1"},
    },{
      plugin: require('/home/runner/work/main-page/main-page/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
